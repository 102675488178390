/* Masonry Carousels */
const mGallery = document.querySelector('#masonryGridGallery') 
let totalSlides = 0
if(mGallery){
  var btPrev = document.querySelector('#mGalleryPrev')
  var btNext = document.querySelector('#mGalleryNext')
  totalSlides = mGallery.querySelectorAll('.grid-masonry-gallery').length

  if(totalSlides > 1) {
    btPrev.style.display = 'flex'
    btNext.style.display = 'flex' 
  }

  btPrev.addEventListener('click', (e) => {
    e.preventDefault()
    mGallerySlide(-1)
    
  })
  btNext.addEventListener('click', (e) => {
    e.preventDefault()
    mGallerySlide(1)
  })
}

function mGallerySlide(s) {
  let nextSlide = Number(mGallery.getAttribute('data-slide')) + s
  if(nextSlide < 0) {
    nextSlide = totalSlides - 1
  } else if(nextSlide >= totalSlides) {
    nextSlide = 0
  } 
  mGallery.setAttribute('data-slide', nextSlide)
  const container = mGallery.querySelector('.gallery-inner')
  const w = document.getElementsByTagName('body')[0].offsetWidth
  TweenMax.to(container, .5, {left:-nextSlide*w})
}


/* Flip.to Images in Footer */
jQuery(function(){
  const flipto = jQuery('#fliptoGallery')
  const url = 'https://flip.to/api/photo-explorer/gallery/photos?companyCampaignCode=GT-TANQUEPHOTOS&pageNumber=1&pageSize=16'
  jQuery.get(url, function(result){
    let images = []
    result.ChunkedFinalists.forEach(function(arr){
      images = images.concat(arr)
    })
    
    if(images.length) {
      for(let i=0; i<4; i++){
        var image = images[i]
        var html = `<a href="https://tanqueverderanch.stories.travel/gallery" target="_blank"><img src="${image.PhotoUriSmall}" alt="${image.Quote}" /></a>`
        flipto.append(html)
      }
    }
  })
  .fail(function() {
    console.log('error getting flipto photos')
  })

  /* Shrinking header */
  $(document).on("scroll", function(){
    if($(document).scrollTop() > 100){
      $("header").addClass("short");
    }
    else
    {
      $("header").removeClass("short");
    }
  });
})


// Wedding Gallery/
var $grid = jQuery('.igrid').isotope({
  itemSelector: '.igrid-item',
  percentPosition: true,
  masonry: {
    columnWidth: '.igrid-sizer'
  }
});

// layout Isotope after each image loads
$grid.imagesLoaded().progress( function() {
  $grid.isotope('layout');
});
